var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":_vm.showApprovedButton
      ? {
          text: 'Approve',
          key: 'approve',
          loading: _vm.makingApiRequest
        }
      : undefined,"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?[_c('div',{staticClass:"flex justify-end pb-4"},[(_vm.editing && _vm.editing.status == 'approved')?_c('el-tag',{staticClass:"pl-3 pr-3",attrs:{"type":"success"}},[_vm._v("APPROVED")]):(_vm.editing && _vm.editing.status == 'rejected')?_c('el-tag',{staticClass:"pl-3 pr-3",attrs:{"type":"danger"}},[_vm._v("REJECTED")]):_vm._e()],1),(_vm.editing)?_c('AgentApplicationDetails',{attrs:{"data":_vm.editing,"isBold":"true"}}):_vm._e(),_c('ModalBase',{attrs:{"name":"approveAgentApplicationModal","size":"medium","clickToClose":false,"showClose":true,"title":"Approve Agency","loading":_vm.makingApiRequest,"position":"center","bottomButtonPrimary":{
        key: 'save',
        label: _vm.__getTextUcFirst('agentApplication', 'submit')
      },"bottomButtonSecondary":{
        key: 'cancel',
        label: 'Cancel'
      }},on:{"primaryButtonClick":_vm.approve}},[_c('el-row',[_c('el-col',[_c('div',{staticClass:"pt-3 pb-3"},[_c('el-select',{attrs:{"multiple":"","filterable":"","placeholder":"Select Company IDs"},model:{value:(_vm.companyIds),callback:function ($$v) {_vm.companyIds=$$v},expression:"companyIds"}},_vm._l((_vm.companies),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.companyName,"value":item._id}})}),1)],1)]),_c('el-col',[_c('div',{staticClass:"pt-3 pb-3"},[_c('TextField',{ref:"field_agentCode",attrs:{"row":8,"label":_vm.__getText('agentApplication', 'agentCode'),"placeholder":_vm.__getText('agentApplication', 'agentCode'),"type":"text","required":true,"validations":[
                {
                  method: 'integer',
                  error: 'Agent Code must be a number'
                }
              ]},on:{"input":_vm.checkInput},model:{value:(_vm.agentCode),callback:function ($$v) {_vm.agentCode=$$v},expression:"agentCode"}})],1)])],1)],1)]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the agent application you were looking for could not be found.","actionButtonTitle":"Back to agent applications","actionRedirectPath":"/admin/agent-applications"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }